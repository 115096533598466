@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  #tooltip {
    @apply bg-indigo-500 text-white font-light p-1.5 rounded text-xs shadow;
  }
  #root {
    @apply select-none;
  }
}

html, body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

/* Popper Elements */

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

/* React Flow */

react-flow__nodesselection-rect,
.react-flow__selection {
  background: rgba(78, 78, 255, 0.1) !important;
  border: 6px dotted rgba(155, 155, 155, 0.8);
}

.react-flow__panel {
  margin: 1.25rem !important;
}

/* Sketchy lines */

/* .sketchy {
  padding: 4rem 8rem;
  display: inline-block;
  border: 3px solid #333333;
  font-size: 2.5rem;
  border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
  text-transform: uppercase;
  letter-spacing: 0.3ch;
  background: #ffffff;
  position: relative;

  &::before {
    content: '';
    border: 2px solid #353535;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
    border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
  }
} */
